// .App {
//   display: flex;
//   width: 100%;
// }

.full-width-expandable {
  width: 100%;
}

.swiper {
  width: 600px;
  height: 300px;
}
