// h6,
// .h6,
// h5,
// .h5,
// h4,
// .h4,
// h3,
// .h3,
// h2,
// .h2,
// h1,
// .h1 {
//   font-weight: 700 !important;
// }

#tab-content {
  .MuiPaper-root {
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 18px;
    box-shadow: none !important;
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }
}
// .MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
//   box-shadow: 5px 5px 15px rgba(117, 117, 117, 0.082) !important;
// }

// .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {

//         border-color: $text-dark;

// }

// .MuiMenu-paper {
//   // background: $grey9;
//   padding: 0 !important;
// }
// p {
//   margin-top: auto !important;
//   margin-bottom: auto !important;
// }
// .table-img {
//   img {
//     width: 50px;
//     height: auto;
//   }
// }
// .banners-img {
//   img {
//     width: 100px !important;
//     height: auto !important;
//   }
// }

// .table-wrapper {
//   display: flex;
//   width: 100%;
//   // flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
//   td {
//     font-size: 0.8rem;
//     font-weight: 600;
//   }
//   th {
//     padding-top: 6px;
//     border-bottom: none !important;
//   }
//   th:first-of-type {
//     // border-top-left-radius: 12px;
//   }
//   th:last-of-type {
//     // border-top-right-radius: 12px;
//   }
// }

// .headersFix {
//   th:first-of-type {
//     border-bottom-left-radius: 8px;
//     border-top-left-radius: 8px;
//   }
//   th:last-of-type {
//     border-bottom-right-radius: 8px;
//     border-top-right-radius: 8px;
//   }
// }

// .my-swal {
//   z-index: 10000 !important;
// }

.isActive {
  .MuiTypography-root {
    color: white;
  }
  .MuiListItemIcon-root {
    color: white;
  }
  background-color: $theme-main !important;
}
.isActiveDropdown {
  .MuiTypography-root {
    color: white;
  }
  .MuiListItemIcon-root {
    color: white;
  }
  .MuiSvgIcon-root {
    color: white;
  }
  background-color: $theme-main !important;
}
.isActiveSubDropdown {
  .MuiTypography-root {
    color: $theme-main !important;
  }
  .MuiListItemIcon-root {
    color: $theme-main !important;
  }
  .MuiSvgIcon-root {
    color: $theme-main !important;
  }
  background-color: white;
}

.profile-form {
}

// .language {
//   .MuiSelect-icon {
//     color: white;
//   }
// }
// // .MuiAccordionSummary-content.Mui-expanded { margin: 0;}

// .css-1rd7fcg-MuiList-root-MuiMenu-list {
//   background-color: rgb(54, 53, 61) !important;
// }
