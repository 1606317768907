body {
  margin: 0;
  padding: 0 !important;
  min-height: 100vh;
  height: 100%;
  background: #001d35 !important;
  color: $theme-main-dark;
  overflow-x: hidden !important;
}
html {
  overflow-x: hidden;
}

// .container {
//   margin: 0 auto;
//   max-width: 1100px;
//   padding: 50px;
// }
// // .container-fluid {
// //   margin: 20px 20px;
// //   padding-right: 20px;
// //   padding-left: 20px;
// // }
// .sec-padding {
//   padding: 120px 0;
// }
// .section {
//   padding-top: 60px;
//   padding-bottom: 60px;
//   position: relative;
//   margin: 0 auto !important;
// }

// a {
//   color: $gold !important;
//   text-decoration: none !important;
// }
// .text-main {
//   color: $theme-main-dark !important;
//   font-weight: 800;
//   padding-right: 3px;
// }

// .m-auto {
//   margin: 0 auto !important;
// }

// .mb-20 {
//   margin-bottom: 20px !important;
// }
// .mb-40 {
//   margin-bottom: 20px !important;
// }
// .mt-20 {
//   margin-top: 20px !important;
// }
// .mt-40 {
//   margin-top: 40px !important;
// }
// .mt-60 {
//   margin-top: 60px !important;
// }
// .mb-60 {
//   margin-bottom: 60px !important;
// }
// .mb-10 {
//   margin-bottom: 10px !important;
// }
// .ml-20 {
//   margin-left: 20px !important;
// }
// .mrml-20 {
//   margin-left: 20px !important;
//   margin-right: 20px !important;
// }
// .ml-auto {
//   margin-left: auto !important;
// }
// .mr-auto {
//   margin-right: auto !important;
// }
// .prpl-20 {
//   padding-left: 20px !important;
//   padding-right: 20px !important;
// }
// .prpl-min {
//   padding-left: 4px !important;
//   padding-right: 4px !important;
// }
// .mcenter {
//   margin: 0 auto !important;
//   text-align: center !important;
//   align-items: center;
//   justify-content: center;
//   display: flex;
// }
// .center {
//   text-align: center !important;
//   align-items: center;
//   justify-content: center;
//   display: flex;
// }
// .space-evenly {
//   justify-content: space-evenly;
//   display: flex;
// }
// // .wrapper {
// //   display: flex;
// //   flex-direction: column;
// //   flex: 4;
// //   width: 100%;

// //   margin-right: auto;
// //   margin-top: 64px;
// //   // min-height: 100vh;
// //   overflow-x: hidden;
// // }

// .flex-column {
//   flex-direction: column !important;
// }

// .icon {
//   vertical-align: middle;
//   color: $gold;
//   font-size: 24px;
// }
// .category-icon {
//   margin-right: 50px;
//   width: 50px;
//   vertical-align: middle;
//   color: $gold;
//   font-size: 36px;
//   font-weight: 800;
// }

// #dashboard {
//   width: 100%;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   align-content: center;
//   margin-top: 45px;

//   .stats {
//     display: flex;
//     justify-content: space-between;
//     flex: wrap;
//     flex-direction: row;
//   }
// }

// .divider {
//   width: 100%;
// }
// #products {
//   width: 100%;
//   // min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   align-content: center;

//   .product-list {
//     display: flex;
//     justify-content: center;
//     align-content: center;
//     // margin: 20px 15px 15px 15px;
//   }
// }

// #tab-content {
//   width: 100%;
//   // min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   align-content: center;

//   .content {
//     display: flex;
//     justify-content: center;
//     flex: wrap;
//     flex-direction: column;
//   }
//   .content-wrapper {
//     min-height: 70vh;
//     display: flex;
//     justify-content: center;
//     align-items: flex-start;
//   }
// }
// #media {
//   width: 100%;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   align-content: center;

//   .media-list {
//     display: flex;
//     justify-content: center;
//     align-content: center;
//     // margin: 20px 15px 15px 15px;
//   }
//   .media-wrapper {
//     //  min-height: 75vh;
//     //   display: flex;
//     //   justify-content: center;
//     //   align-items: flex-start;
//   }
//   .drag-n-drop {
//     height: 125px;
//     width: 75%;
//     border-radius: 24px;
//     border: 1px solid $theme-main;
//     margin: 10px 0;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//   }
// }
// .preview-img {
//   img {
//     width: 100px;
//     height: auto;
//   }
// }

// #login {
//   .login-wrapper {
//     height: 100vh;
//     width: 100vw;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     align-content: center;
//     background: linear-gradient(62deg, #242424 0%, #3a3a3a 100%);
//   }
//   .fruver-logo {
//     margin-bottom: 1.5em;
//     img {
//       width: auto;
//       height: 60px;
//     }
//   }
//   // .form__field {
//   //   display: flex;
//   //   flex-direction: row;
//   //   justify-content: center;
//   //   align-items: center;

//   //   width: 350px;
//   //   margin: 0.875rem auto;
//   // }

//   // input {
//   //   display: flex;
//   //   flex: 2;
//   //   color: $text-dark;
//   //   transition: background-color 0.3s;
//   //   width: 100%;
//   //   margin-left: auto;
//   // }
//   // label {
//   //   display: flex;
//   //   flex: 1;
//   //   color: $text-dark;
//   //   font-weight: 800;
//   //   padding-right: 20px;
//   //   margin-right: auto;
//   // }
//   .error {
//     width: 240px;
//     position: absolute;
//     color: $theme-main-red;
//     margin-bottom: -35px;
//     padding: 0.5em 0.2em;
//     height: 1em;
//     font-size: 0.8em;
//     margin-left: auto;
//     margin-right: auto;
//     left: 0;
//     right: 0;
//     text-align: center;
//   }
//   .align {
//     align-items: center;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//   }

//   // .hidden {
//   //   border: 0;
//   //   clip: rect(0 0 0 0);
//   //   height: 1px;
//   //   margin: -1px;
//   //   overflow: hidden;
//   //   padding: 0;
//   //   position: absolute;
//   //   width: 1px;
//   // }

//   input[type="submit"] {
//     cursor: pointer;
//   }
//   .swal2-container {
//     z-index: 99999 !important;
//   }
//   .my-swal-popup-class {
//     z-index: 99999 !important;
//   }
// }

// .swal2-spinner {
//   margin: 0 auto;
//   width: 40px;
//   height: 40px;
//   position: relative;
//   box-sizing: border-box;
//   border: 4px solid #f3f3f3;
//   border-left: 4px solid #3498db;
//   border-radius: 50%;
//   animation: spin 1s linear infinite;
// }

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
