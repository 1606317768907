@import "./assets/scss/variables";
@import "./assets/scss/theme";
@import "@iofate/react-coinbase-commerce/dist/esm/index.css";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
html {
  overflow-y: auto;
  overflow-x: hidden;
}

body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #001d35;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollable-element::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

body::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

html {
  scroll-behavior: smooth;
}
div:where(.swal2-container) {
  display: grid;
  position: fixed;
  z-index: 10600 !important;
  inset: 0;
  box-sizing: border-box;
  grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
  grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(
      min-content,
      auto
    );
  height: 100%;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .slide {
  min-width: 100%;
  margin: 0;
  position: relative;
  text-align: center;
}
.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
}
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0;
}
.coinbase-commerce-modal {
  background-color: white !important;
}

/* Existing button styles */
button.coinbase-commerce-button {
  border-radius: 6px;
  background-color: #0667d0;
  background: linear-gradient(#0667d0, #0655ab);
  color: white;
  height: 40px;
  font-size: 14px;
  font-family: Avenir Next, sans-serif;
  font-weight: 500;
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  text-decoration: none;
  cursor: pointer;
}

button.coinbase-commerce-button:hover {
  background: #0666d0;
}

button.coinbase-commerce-button:active {
  background: #0655ab;
}

button.coinbase-commerce-button:focus {
  outline: none;
}

button.coinbase-commerce-button:disabled {
  background: #7d95b6;
  color: rgba(0, 0, 0, 0.4);
  cursor: not-allowed;
}

button.coinbase-commerce-button > span {
  color: #fff;
  font: normal 500 14px/20px -apple-system, BlinkMacSystemFont,
    ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue",
    "Lucida Grande", sans-serif;
  letter-spacing: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
  white-space: nowrap;
}

div.coinbase-commerce-iframe-container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99998;
  background-color: rgba(255, 255, 255, 1) !important;
}

iframe.coinbase-commerce-iframe {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  border: none;
}

div.commerce-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(6, 103, 208, 0.05);
  border-radius: 100%;
  border-top-color: white;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  33% {
    transform: rotate(90deg);
  }
  66% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.slider-container {
  // width: 1200px;
}
