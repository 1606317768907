//colors
$theme-main: #004885;
$opaque-green: #70c283;
$theme-main-red: #ff5445;
$theme-main-dark: #272a31;
$dark: #181619;
$theme-secondary: #feb93a;
$theme-main-highlight: #94ff74;
$text-dark: #2d303d;
$text-grey: #454857;
$gold: #ffc000;
$gold-highlight: #ffd08e;
$white: #ffffff;
$white-dark: #e2e2e2;
$white-1: #f1f3f5;
$black: #000000;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color: #333333;
$grey-light: #dddddd;
$grey: #777777;
$grey2: #e0e0df;
$grey3: #c3c3c3;
$grey4: #444;
$grey5: #dedede;
$grey6: #e5e5e5;
$grey7: #787878;
$grey8: #d9d9d9;
$grey9: #f3f3f3;
$grey10: #dddddd;
$baners-background: #f5f5f5;
$background: #fafafa;
$gray-lighter: #f6f6f6;
$footer-background: #f7f7f7;
$hovergradient: linear-gradient(35deg, #004fee 0, #f4f4f7 100%) !important;

$dark-theme: #2b2b2b;
$northtec-50: #f1fafc !default;
$northtec-100: #cfeff6 !default;
$northtec-200: #a0dfec !default;
$northtec-300: #70d0e3 !default;
$northtec-400: #40c0d9 !default;
$northtec-500: #25a3bc !default;
$northtec-600: #2192a9 !default;
$northtec-700: #1e8296 !default;
$northtec-800: #1a7283 !default;
$northtec-900: #166170 !default;
$northtec-a100: #abe7f5 !default;
$northtec-a200: #82dbed !default;
$northtec-a400: #30bdd9 !default;
$northtec-a700: #0b5f70 !default;
$northtec: $northtec-500 !default;

$assetspath: "/assets/images";

// Gradients

.bg-gradient-primary {
  background: linear-gradient(35deg, #5e72e4 0, #825ee4 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(35deg, #f4f5f7 0, #f4f4f7 100%) !important;
}

.bg-gradient-success {
  background: linear-gradient(35deg, #2dce89 0, #2dcecc 100%) !important;
}

.bg-gradient-info {
  background: linear-gradient(35deg, #11cdef 0, #1171ef 100%) !important;
}

.bg-gradient-warning {
  background: linear-gradient(35deg, #fb6340 0, #fbb140 100%) !important;
}

.bg-gradient-danger {
  background: linear-gradient(35deg, #f5365c 0, #f56036 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(35deg, #adb5bd 0, #adaebd 100%) !important;
}

.bg-gradient-dark {
  background: linear-gradient(35deg, #212529 0, #212229 100%) !important;
}

.bg-gradient-default {
  background: linear-gradient(35deg, #69d482 0, #fdff74 100%) !important;
}

.bg-gradient-white {
  background: linear-gradient(35deg, #fff 0, white 100%) !important;
}

.bg-gradient-neutral {
  background: linear-gradient(35deg, #fff 0, white 100%) !important;
}

.bg-gradient-darker {
  background: linear-gradient(35deg, black 0, black 100%) !important;
}

// OTHER ELEMENTS //
hr.style1 {
  border-top: 1px solid #8c8b8b;
}

hr.style2 {
  border-top: 3px double #8c8b8b;
}

hr.style3 {
  border-top: 1px dashed #8c8b8b;
}

hr.style4 {
  border-top: 1px dotted #8c8b8b;
}

hr.style5 {
  background-color: #fff;
  border-top: 2px dashed #8c8b8b;
}

hr.style6 {
  background-color: #fff;
  border-top: 2px dotted #8c8b8b;
}

hr.style7 {
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #fff;
}

hr.style8 {
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #fff;
}
hr.style8:after {
  content: "";
  display: block;
  margin-top: 2px;
  border-top: 1px solid #8c8b8b;
  border-bottom: 1px solid #fff;
}

hr.style9 {
  border-top: 1px dashed #8c8b8b;
  border-bottom: 1px dashed #fff;
}

hr.style10 {
  border-top: 1px dotted #8c8b8b;
  border-bottom: 1px dotted #fff;
}

hr.style11 {
  height: 6px;
  background: url(http://ibrahimjabbari.com/images/hr-11.png) repeat-x 0 0;
  border: 0;
}

hr.style12 {
  height: 6px;
  background: url(http://ibrahimjabbari.com/images/hr-12.png) repeat-x 0 0;
  border: 0;
}

hr.style13 {
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8b8b inset;
}

hr.style14 {
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
}

hr.style15 {
  border-top: 4px double #8c8b8b;
  text-align: center;
}
hr.style15:after {
  content: "\002665";
  display: inline-block;
  position: relative;
  top: -15px;
  padding: 0 10px;
  background: #f0f0f0;
  color: #8c8b8b;
  font-size: 18px;
}

hr.style16 {
  border-top: 1px dashed #8c8b8b;
}
hr.style16:after {
  content: "\002702";
  display: inline-block;
  position: relative;
  top: -12px;
  left: 40px;
  padding: 0 3px;
  background: #f0f0f0;
  color: #8c8b8b;
  font-size: 18px;
}

hr.style17 {
  border-top: 1px solid #8c8b8b;
  text-align: center;
}
hr.style17:after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -14px;
  padding: 0 10px;
  background: #f0f0f0;
  color: #8c8b8b;
  font-size: 18px;
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  transform: rotate(60deg);
}

hr.style18 {
  height: 30px;
  border-style: solid;
  border-color: #8c8b8b;
  border-width: 1px 0 0 0;
  border-radius: 20px;
}
hr.style18:before {
  display: block;
  content: "";
  height: 30px;
  margin-top: -31px;
  border-style: solid;
  border-color: #8c8b8b;
  border-width: 0 0 1px 0;
  border-radius: 20px;
}
