
#chat {


  .chat-list {
    display: flex;
    justify-content: center;
    flex: wrap;
    flex-direction: column;
    // margin: 20px 15px 15px 15px !important;
    .chat-wrapper {
      min-height: 75vh;

    }
  }
}
.chat-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px #f3f3f3;
  border-radius: 18px;

}

.chat-box {
  width: 600px;
  height: 400px;
 
  border-radius: 18px;
}
.message-box {
  width: 600px;
  height: 150px;
  margin: 20px;

  border-radius: 18px;
}
.cheader-title {
  .MuiTypography-root {
    font-family: "Mulish","Helvetica","Arial",sans-serif;
    font-weight: 800;
}
}
.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 867px;
  // border-radius: 18px;
  // background: var(--msger-bg);
  box-shadow: 5px 20px 15px rgba(117, 117, 117, 0.082);
  &::-webkit-scrollbar{
    width: 7;
  };
  &::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3),
  };
  &::-webkit-scrollbar-thumb {
    background-color: "darkgrey";
    outline: 1px solid slategrey;
  };
}

.msger-header {
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #eee;
  color: #579ffb;
border-top-left-radius: 18px;
border-top-right-radius: 18px;
}
.msger-header-title {
  span {
    font-weight: 800;
  }
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  color: $theme-main-dark;
  border-radius: 18px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}

.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: var(--right-msg-bg);
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}

.msger-inputarea {
  display: flex;
  padding: 20px;
  border-top: var(--border);
  background: $theme-main-dark;
  border-radius: 18px;
}
.msger-inputarea * {
  padding: 20px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  background: #ddd;
}
.msger-send-btn {
  margin-left: 10px;
  color: #ffffff;
  background-color: $theme-main;
  border-color: $theme-main;
  border-radius: 18px !important;
  font-weight: bold;
  cursor: pointer;
}
.msger-send-btn:hover {
  background: $theme-main-dark;
}

.msger-chat {
  background-color: #fcfcfe;
}
